.verify-content{
    // padding: 4.5rem 0 0;
    padding: calc(70px + 24px) calc(24px * 0.75) 60px calc(24px * 0.75)
}
.email-wrapper{
    background-color: rgba(85, 110, 230, 0.25);
    height: 100%;
    padding: 10px;
}
.invalid-wrapper{
    background-color: #c4cdff;
    border-radius: 10px;
    margin-top: 5rem !important;
}
// .email-info{
//     border-radius: 50px;
//     width: 100%;
//     padding: 5px;
//     display: flex;
//     align-items: center;
//     color: #fff;
//     font-size: 15px;
//     font-weight: 600;
//     i{
//         color: #fff;
//         font-size: 28px;
//         line-height: 30px;
//     }
//     .circle{
//         border-radius: 50px;
//         display: inline-block;
//         margin-right: 10px;
//         width: 30px;
//         height: 30px;
//         text-align: center;
//     }
// }
// .pending-info{
//     background-color: #556ee6;
// }
// .pending-info-circle{
//     background: #9fb0ff;
    
// }
// .success-info{
//     background-color: #2ca67a;
// }
// .success-info-circle{
//     background: #34c38f;
    
// }
// .unknown-info{
//     background-color: #f1b44c;
// }
// .unknown-info-circle{
//     background: #dd9313;
    
// }
// .failed-info{
//     background-color: #74788d;
// }
// .failed-info-circle{
//     background: #adb5bd;
    
// }
// .error-info{
//     background-color: #f46a6a;

// }
// .error-info-circle{
//     background: #ff9c9c;
    
// }