.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 100%;
  border-radius: 0;
  border: none;
  margin: 0;
  font-size: 12px;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 40px;
  margin: 0;
  font-size: 15px;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 20px;
  outline: none;
  background: #fff;
}
